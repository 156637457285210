<template>
  <div v-if="shops">
    <CCard color="white" text-color="black" class="text-center shadow-sm" body-wrapper>
      <CRow>
        <CCol md="12">
          <CRow>
            <CCol md="2"></CCol>
            <CCol md="8">
              <h2 class="font-weight-normal">{{ $t('createVoucher') }}</h2>
            </CCol>
            <CCol md="2" class="text-right">
              <a @click="$router.go(-1)">
                <CButton>
                  <CIcon name="cil-arrow-thick-from-right" />
                  {{ $t('back') }}
                </CButton>
              </a>
            </CCol>
          </CRow>
          <CAlert color="success" v-if="isSaveSuccess != ''">
            {{ isSaveSuccess }}
          </CAlert>
        </CCol>
      </CRow>
      <hr />
      <br />
      <p class="text-danger font-weight-normal text-left">{{ alert }}</p>
      <CRow>
        <CCol md="6">
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left text-black font-weight-normal">
                  {{ $t('img') }}
                  <span class="text-danger font-weight-normal">*</span>
                </label>
                <div class="col-sm-9">
                  <CJumbotron v-if="url" color="white" border-color="grey-200" class="pointer">
                    <img alt="" :src="url" width="50%" />
                  </CJumbotron>
                  <CJumbotron color="white" border-color="grey-200" v-else class="pointer">
                    <div class="custom-file mb-3">
                      <input style="border-color:white" type="file" class="text-black form-control pointer" required
                        @change="onFileSelect" />
                      <br />
                      <label class="custom-file text-left text-description" style="font-size:12px">
                        {{ $t('selectedFile') }} ...
                        <span class="text-info">
                          {{ $t('fileSize') }}
                        </span>
                      </label>
                    </div>
                  </CJumbotron>
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left">
                  <strong>
                    {{ $t('voucherName') }}
                    <span class="text-danger font-weight-normal">*</span>
                  </strong>
                </label>
                <div class="col-sm-9">
                  <CInput v-model="title" :placeholder="$t('voucherNamePlaceholder')" />
                </div>
              </div>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left ">
                  <strong>
                    {{ $t('detail') }}
                    <span class="text-danger font-weight-normal">*</span>
                  </strong>
                </label>
                <div class="col-sm-9">
                  <CTextarea v-model="description" :placeholder="$t('voucherDetailPlaceholder')" rows="7" />
                </div>
              </div>
            </CCol>
          </CRow>
        </CCol>

        <CCol md="6">
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-3 col-form-label text-left">
                  <strong>
                    {{ $t('voucherValue') }}
                    <span class="text-danger font-weight-normal">*</span>
                  </strong>
                </label>
                <div class="col-sm-9">
                  <CInput type="number" placeholder="กรอกมูลค่าบัตรกำนัล..." v-model.number="value">
                    <template #append-content>
                      {{ $t('baht') }}
                    </template>
                  </CInput>
                </div>
              </div>
            </CCol>
          </CRow>

          <p class="text-left font-weight-normal">
            {{ $t('voucherExpire') }}
            <span class="text-danger font-weight-normal">*</span>
          </p>
          <hr />
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-12 col-form-label text-left text-grey pointer">
                  <input type="radio" v-model="isExpiration" value="true" />
                  {{ $t('setPeriod') }}
                </label>
              </div>
              <input v-if="isExpiration === 'false' || isExpiration === ''" class="text-black form-control" disabled />
              <v-md-date-range-picker v-else opens="center" :presets="null" :currentLang="getCurrentLang()"
                :locale="getCurrentLang()" @change="handleChangeDate" style="width: 100%;">
                <template slot="input" class="activator-wrapper">
                  <input type="text" readonly="readonly" style="background-color: white;"
                    class="form-control input-group-text" :value="dateText" :placeholder="$t('selectDate')" />
                </template>
              </v-md-date-range-picker>
            </CCol>
          </CRow>
          <br />
          <CRow>
            <CCol md="12">
              <div class="form-group row">
                <label class="col-sm-12 col-form-label text-left text-grey pointer">
                  <input type="radio" v-model="isExpiration" value="false" />
                  {{ $t('setPeriodAfterRecivcedVoucher') }}
                </label>
              </div>
              <CInput class="text-black" type="number" v-model.number="expirationAt">
                <template #append-content>
                  {{ $t('days') }}
                </template>
              </CInput>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
      <CButton block color="success" class="px-4" @click="handlePopup()">
        {{ $t('preview') }}
      </CButton>
    </CCard>

    <CModal :show.sync="popupModal" centered header="false" :footer="footer">
      <CRow>
        <CCol md="12">
          <CCard border-color="light">
            <table aria-describedby="">
              <tr>
                <th scope="col" style="background-color:#ebedef;width:35%">
                  <CCardBody>
                    <img alt="" class="img-fluid" :src="imgUrl" />
                  </CCardBody>
                </th>
                <td>
                  <CCardBody class="text-left text-dark">
                    <h4>
                      {{ title }}
                    </h4>
                    <hr />
                    <p>{{ description }}</p>
                    <p><strong>{{ $t('value') }} : </strong> {{ value }} {{ $t('baht') }}</p>
                    <p v-if="expirationAt !== ''" class="font-weight-normal">
                      {{ $t('periodAfterRecivedVoucher') }} {{ expirationAt }} {{ $t('days') }}
                    </p>
                    <p v-else-if="expirationAt === ''">
                      <strong>{{ $t('startFrom') }} : </strong>
                      {{ startAtShow }} - {{ endAtShow }}
                    </p>
                  </CCardBody>
                </td>
              </tr>
            </table>
          </CCard>
        </CCol>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center">
          <CCol col="4">
            <CButton block color="success" @click="saveVoucher()">
              {{ $t('save') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="danger" @click="popupModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>

    <CModal :show.sync="imgModal" centered header="false" :footer="footer">
      <CRow class="justify-content-center col-md-12">
        <CCard color="white" text-color="dark" class="text-center col-md-12" body-wrapper>
          <CCardBody color="light">
            <VueCropper v-show="selectedFile" ref="cropper" :src="selectedFile" alt="Source Image">
            </VueCropper>
          </CCardBody>
        </CCard>
      </CRow>
      <template #footer-wrapper>
        <CRow class="justify-content-center col-md-12">
          <CCol md="4" v-if="loadingButton === true">
            <CButton block color="success" @click="editImg()">
              {{ $t('submit') }}
            </CButton>
          </CCol>
          <CCol md="4" v-else-if="loadingButton === false">
            <CButton block color="success" disabled>
              <CSpinner color="light" size="sm" /> {{ $t('loading') }}
            </CButton>
          </CCol>
          <CCol col="4">
            <CButton block color="light" @click="imgModal = false">
              {{ $t('cancel') }}
            </CButton>
          </CCol>
        </CRow>
        <br />
        <br />
      </template>
    </CModal>
  </div>
  <div v-else>
    <no-shop></no-shop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment'
import crm from '@/services/crm'
import pos from '@/services/pos'
import util from '@/util/util'
import NoShop from '@/containers/NoShop'

export default {
  components: { VueCropper, DatePicker, NoShop },
  data() {
    return {
      imgModal: false,
      selectedFile: '',
      cropedImage: '',
      loadingButton: true,
      alert: '',
      url: '',
      title: '',
      description: '',
      startAt: '',
      endAt: '',
      expirationAt: '',
      startAtShow: '',
      endAtShow: '',
      value: 0,
      isExpiration: '',
      popupModal: false,
      footer: '',
      image: '',
      imgUrl: '',
      values: [],
      date: [new Date(), new Date()],
      isSaveSuccess: '',
      dateText: null,
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
  },
  methods: {
    ...util,
    getRange(startOffset = 0, endOffset = 0, period = 'day') {
      return [moment().subtract(startOffset, period).startOf(period), moment().subtract(endOffset, period).endOf(period)];
    },
    handleChangeDate(values) {
      const date = {
        start: moment(values[0]).toISOString(),
        end: moment(values[1]).toISOString(),
      }
      this.dateText = moment(values[0])
        .locale('en')
        .format('DD/MM/YYYY') + " - " +
        moment(values[1])
          .locale('en')
          .format('DD/MM/YYYY')
      this.startAt = date.start
      this.endAt = date.end
    },
    getCurrentLang() {
      return this.$i18n.locale
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0))
    },
    handleChange(values) {
      const date = {
        start: moment(values[0]).toISOString(),
        end: moment(values[1]).toISOString(),
      }
      this.startAt = date.start
      this.endAt = date.end
    },
    handlePopup() {
      let validateData = this.validateData()

      if (validateData === true) {
        this.popupModal = true
        if (this.startAt !== '') {
          this.startAtShow = moment(this.startAt).format('DD/MM/YYYY')
        } else {
          this.startAtShow = ''
        }

        if (this.endAt !== '') {
          this.endAtShow = moment(this.endAt).format('DD/MM/YYYY')
        } else {
          this.endAtShow = ''
        }
      } else {
        this.popupModal = false
      }
    },
    editImg() {
      this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.uploadImage(this.cropedImage)
    },
    onFileSelect(e) {
      const file = e.target.files[0]

      this.imgModal = true

      this.mime_type = file.type
      if (typeof FileReader === 'function') {
        this.dialog = true
        const reader = new FileReader()
        reader.onload = (event) => {
          this.selectedFile = event.target.result
          this.$refs.cropper.replace(this.selectedFile)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    handleImage(e) {
      const file = e.target.files[0]

      this.createBase64Img(file)
    },
    createBase64Img(fileObject) {
      this.url = URL.createObjectURL(fileObject)
      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
        this.uploadImage(this.image)
      }

      reader.readAsDataURL(fileObject)
    },
    uploadImage(image) {
      let data = {
        image: image,
      }

      this.loadingButton = false

      pos
        .post('/images/v1.0/upload/general/' + this.uid, data)
        .then((res) => {
          if (res.data.error.code === 0) {
            //บันทึกสำเร็จ
            let baseUrl = res.data.data.baseUrl
            let url = res.data.data.url

            this.imgUrl = baseUrl + url
            this.url = this.imgUrl
            this.imgModal = false
            this.loadingButton = true
          } else {
            //มีบางอย่างผิดพลาด
            console.log(res.data.error.message)
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
    validateData() {
      if (
        this.imgUrl === '' ||
        this.title === '' ||
        this.description === '' ||
        this.isExpiration === ''
      ) {
        this.alert = this.$i18n.t('validateInput')
      } else {
        if (this.isExpiration === 'true') {
          if (this.startAt === '' && this.endAt === '') {
            this.alert = this.$i18n.t('validDateInput')
          } else {
            this.alert = ''
          }
          this.expirationAt = ''
        } else if (this.isExpiration === 'false') {
          this.startAt = ''
          this.endAt = ''
          if (this.expirationAt === '') {
            this.alert = this.$i18n.t('validDateInput')
          } else {
            this.alert = ''
          }
        }

        return this.alert === ''
      }
    },
    saveVoucher() {
      const uid = this.uid
      const users = this.users

      let createdBy = {
        shopName: users.shopName,
        currentPlan: users.currentPlan,
      }

      let data = {
        uid: uid,
        imgUrl: this.imgUrl,
        title: this.title,
        description: this.description,
        value: this.value,
        startAt: this.startAt,
        endAt: this.endAt,
        expirationAt: this.expirationAt,
        createdBy: createdBy,
        updatedBy: createdBy,
        serialNumber: util.generateSerialNumber(),
      }

      crm
        .post('/api/v1.0/' + uid + '/voucher/save', data)
        .then((res) => {
          if (res.data.error.code === 0) {
            //บันทึกสำเร็จ
            this.isSaveSuccess = this.$i18n.t('createVoucherCompleted')
            this.$router.push('/voucher#voucher')
          } else {
            //มีบางอย่างผิดพลาด
            alert(this.$i18n.t('errorAlert'))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
